<template>
  <v-app style="background-color: rgb(234, 235, 238);">
    <v-navigation-drawer v-model="drawer" absolute temporary app>
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <div style="width: 100%;" class="mb-5">
              <div>
                <v-btn color="white" class="" style="font-weight: 1000; font-size: 20px; color: rgb(255, 168, 0);"
                  elevation="0" to="/">
                  Minaret </v-btn>
              </div>

              <div class="my-5">
                <v-btn rounded outlined elevation="0" class="ma-1 black--text" to="/Contact">
                  contact
                </v-btn>
                <v-btn rounded outlined elevation="0" class="ma-1 black--text" to="/PotentialClients">
                  Potential Clients
                </v-btn>
                <v-btn rounded outlined elevation="0" class="ma-1 black--text" to="/Clients">
                  Clients
                </v-btn>
                <v-btn rounded outlined elevation="0" class="ma-1 black--text" to="/countClient">
                  Count Client
                </v-btn>
                <v-btn rounded outlined elevation="0" class="ma-1 black--text" to="/statisticsTable">
                  Statistics Table
                </v-btn>
                <v-btn rounded outlined elevation="0" class="ma-1 black--text" v-if="isLogin" to="/Employees">
                  employees
                </v-btn>
                <v-btn rounded outlined elevation="0" class="ma-1 black--text" v-if="isLogin" to="/EntryPage">
                  Entry page
                </v-btn>
              </div>

              <div class="my-5">
                <v-btn style="background-color: rgb(255, 168, 0);" elevation="0" class="ma-1" @click="clearPassword"
                  v-if="isLogin"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path fill="currentColor"
                      d="m17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5M4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5Z" />
                  </svg>
                </v-btn>
                <v-dialog v-if="!isLogin" v-model="dialog" eager width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn style="background-color: rgb(255, 168, 0);" dark class="ma-1" v-bind="attrs" elevation="0"
                      v-on="on" @click="doFocus()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="currentColor"
                          d="M11 7L9.6 8.4l2.6 2.6H2v2h10.2l-2.6 2.6L11 17l5-5l-5-5m9 12h-8v2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-8v2h8v14Z" />
                      </svg>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text class="py-10">
                      <v-text-field outlined type="password" dense ref="passowrdInput" @keyup.enter="checkToEdit"
                        v-model="password" label="password"></v-text-field>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="checkToEdit">
                        Ok
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list> </v-navigation-drawer>
    <v-app-bar elevation="0" app color="white" class="my-5 mx-8 rounded-lg" radios dark v-if="!largScreen">
      <div class="d-flex justify-space-between" style="width: 100%;">
        <div>
          <v-btn color="orange" elevation="1" dark @click.stop="drawer = !drawer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M3 6h18v2H3V6m0 5h18v2H3v-2m0 5h18v2H3v-2Z" />
            </svg>
          </v-btn>
          <v-btn color="white" class="black--text" @click="review" elevation="0">
            <svg v-if="getReview" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M14 19h4V5h-4M6 19h4V5H6v14Z" />
            </svg>
            <svg v-if="!getReview" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M8 5.14v14l11-7l-11-7Z" />
            </svg> </v-btn>
        </div>
        <div>
          <v-btn style="background-color: rgb(255, 168, 0);" elevation="0" class="ma-1" @click="clearPassword"
            v-if="isLogin"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor"
                d="m17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5M4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5Z" />
            </svg>
          </v-btn>
          <v-dialog v-if="!isLogin" v-model="dialog" eager width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn style="background-color: rgb(255, 168, 0);" dark class="ma-1" v-bind="attrs" elevation="0" v-on="on"
                @click="doFocus()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path fill="currentColor"
                    d="M11 7L9.6 8.4l2.6 2.6H2v2h10.2l-2.6 2.6L11 17l5-5l-5-5m9 12h-8v2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-8v2h8v14Z" />
                </svg>
              </v-btn>
            </template>
            <v-card>
              <v-card-text class="py-10">
                <v-text-field outlined type="password" dense ref="passowrdInput" @keyup.enter="checkToEdit"
                  v-model="password" label="password"></v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="checkToEdit">
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </v-app-bar>
    <v-app-bar elevation="0" app color="white" class="my-5 mx-8 rounded-lg" radios dark v-if="active && largScreen">
      <v-spacer></v-spacer>
      <div class="d-flex justify-space-between" style="width: 100%;">
        <div>
          <v-btn :small="mediumScreen" color="white" class="" style="font-weight: 1000; font-size: 20px; color: rgb(255, 168, 0);" elevation="0"
            to="/">
            Minaret </v-btn>
          <v-btn :small="mediumScreen" color="white" class="black--text" @click="review" elevation="0">
            <svg v-if="getReview" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M14 19h4V5h-4M6 19h4V5H6v14Z" />
            </svg>
            <svg v-if="!getReview" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M8 5.14v14l11-7l-11-7Z" />
            </svg> </v-btn>
        </div>

        <div>
          <v-btn rounded outlined elevation="0" :small="mediumScreen" class="ma-1 black--text" to="/Contact">
            contact
          </v-btn>
          <v-btn rounded outlined elevation="0" :small="mediumScreen" class="ma-1 black--text" to="/PotentialClients">
            Potential Clients
          </v-btn>
          <v-btn rounded outlined elevation="0" :small="mediumScreen" class="ma-1 black--text" to="/Clients">
            Clients
          </v-btn>
          <v-btn rounded outlined elevation="0" :small="mediumScreen" class="ma-1 black--text" to="/countClient">
            Count Client
          </v-btn>
          <v-btn rounded outlined elevation="0" :small="mediumScreen" class="ma-1 black--text" to="/statisticsTable">
            Statistics Table
          </v-btn>
          <v-btn rounded outlined elevation="0" :small="mediumScreen" class="ma-1 black--text" v-if="isLogin" to="/Employees">
            employees
          </v-btn>
          <v-btn rounded outlined elevation="0" :small="mediumScreen" class="ma-1 black--text" v-if="isLogin" to="/EntryPage">
            Entry page
          </v-btn>
        </div>

        <div class="">
          <v-btn :small="mediumScreen" style="background-color: rgb(255, 168, 0);" elevation="0" class="ma-1" @click="clearPassword"
            v-if="isLogin"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor"
                d="m17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5M4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5Z" />
            </svg>
          </v-btn>
          <v-dialog v-if="!isLogin" v-model="dialog" eager width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :small="mediumScreen" style="background-color: rgb(255, 168, 0);" dark class="ma-1" v-bind="attrs" elevation="0" v-on="on"
                @click="doFocus()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path fill="currentColor"
                    d="M11 7L9.6 8.4l2.6 2.6H2v2h10.2l-2.6 2.6L11 17l5-5l-5-5m9 12h-8v2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-8v2h8v14Z" />
                </svg>
              </v-btn>
            </template>
            <v-card>
              <v-card-text class="py-10">
                <v-text-field outlined type="password" dense ref="passowrdInput" @keyup.enter="checkToEdit"
                  v-model="password" label="password"></v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="checkToEdit">
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <div v-on:mouseover="showHeader">
        <div style="width: 100%;height: 50px;"></div>
      </div>
      <router-view />
    </v-main>
    <template>
      <!-- <v-footer
    dark
    padless
  >
    <v-card
      flat
      tile
      color="light-blue darken-4"
      class=" white--text text-center"
      style="width: 100%;"
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4 white--text"
          icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text ">
        {{ new Date().getFullYear() }} — <strong>Minaret</strong>
      </v-card-text>
    </v-card>
  </v-footer> -->
    </template>
  </v-app>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'App',

  data: () => ({
    active: false,
    dialog: false,
    password: '',
    interval: '',
    clientId: '',
    contentId: '',
    largScreen: true,
    mediumScreen : false,
    drawer: false,
    potentialClintId: '',
    countClientId: '',
    statisticsTableId: '',
  }),
  computed: {
    getReview() {
      return this.$store.getters['statisticStore/review']
    },
    isLogin() {
      return this.$store.getters[`login/isLogin`]
    },
  },
  methods: {
    review() {
      if (!this.getReview) {
        this.$store.commit('statisticStore/setReview', true)

        this.$router.push({ name: 'Contact' })

        this.potentialClintId = setTimeout(() => {
          this.$router.push({ name: 'PotentialClients' })
        }, 10000)

        this.clientId = setTimeout(() => {
          this.$router.push({ name: 'Clients' })
        }, 20000)

        this.countClientId = setTimeout(() => {
          this.$router.push({ name: 'countClient' })
        }, 30000)

        this.statisticsTableId = setTimeout(() => {
          this.$router.push({ name: 'statisticsTable' })
        }, 40000)


        this.interval = setInterval(() => {
          this.$router.push({ name: 'Contact' })
          this.potentialClintId = setTimeout(() => {
            this.$router.push({ name: 'PotentialClients' })
          }, 10000)
          this.clientId = setTimeout(() => {
            this.$router.push({ name: 'Clients' })
          }, 20000)
          this.clientId = setTimeout(() => {
            this.$router.push({ name: 'countClient' })
          }, 30000)
          this.clientId = setTimeout(() => {
            this.$router.push({ name: 'statisticsTable' })
          }, 40000)
        }, 50000)
      } else {
        console.log('reviewend');
        // this.$router.push({ name: 'home' })
        // location.reload();
        clearInterval(this.interval);
        clearTimeout(this.clientId)
        clearTimeout(this.contentId)
        clearTimeout(this.potentialClintId)
        clearTimeout(this.countClientId);
        clearTimeout(this.statisticsTableId);
        // clearInterval(this.interval)
        this.$store.commit('statisticStore/setReview', false)
      }
    },
    showHeader() {
      if (this.$route.name != 'Employees' && this.$route.name != 'EntryPage') {
        this.active = !this.active
      } else {
        this.active = true
      }
    },
    checkToEdit() {
      if (this.password == 'st@gmtcc') {
        this.dialog = false
        this.password = ''
        this.$router.push({ name: 'EntryPage' })
        localStorage.setItem('password', '2794n#dfos2djspgk#efnas#fpdfnoj320djsljaskjddfgigwoefmsakdojsd')
        this.checkPassordKeyIsExist()
      }
    },
    doFocus() {
      this.$refs.passowrdInput.focus()
    },

    clearPassword() {
      localStorage.removeItem("password");
      this.$store.commit(`login/setLogin`, false)
      this.$router.push({ name: 'home' })
    },
    checkPassordKeyIsExist() {
      if (localStorage.getItem('password')) {
        this.$store.commit(`login/setLogin`, true)
      } else {
        this.$store.commit(`login/setLogin`, false)
      }
    },
    myEventHandler() {
      console.log('window.innerWidth',window.innerWidth);
      if (window.innerWidth >= 1500) {
      this.largScreen = true
      this.mediumScreen = false
    }else if(window.innerWidth < 1500 && window.innerWidth > 1296 ){
      this.largScreen = true
      this.mediumScreen = true
    } else {
      this.largScreen = false
      this.mediumScreen = false
    }
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },

  mounted() {
    if (window.innerWidth >= 1500) {
      this.largScreen = true
      this.mediumScreen = false
    }else if(window.innerWidth < 1500 && window.innerWidth > 1296  ){
      this.largScreen = true
      this.mediumScreen = true
    } else {
      this.largScreen = false
      this.mediumScreen = false
    } this.$nextTick(() => {
      window.addEventListener('resize', this.myEventHandler);
    })
  },
  created() {
    this.checkPassordKeyIsExist()
    // axios.get('api/closureDate').then(res => {
    //   this.$store.commit('statisticStore/setAllClosure_date', res.data)
    // })
    // axios.get('api/potentialClientStatistics').then(res => {
    //   this.$store.commit('statisticStore/setPotentialClientStatistics', res.data)
    // })
    // axios.get('api/contactStatistics').then(res => {
    //   this.$store.commit('statisticStore/setContactStatistics', res.data)
    // })
    // axios.get('api/clientStatistics').then(res => {
    //   this.$store.commit('statisticStore/setClientStatistics', res.data)
    // })
    // axios.get('api/filterView').then(res => {
    //   this.$store.commit('statisticStore/setView', res.data)
    // })
    // axios.get('api/totalClient').then(res => {
    //   this.$store.commit('statisticStore/setTotalClient', res.data)
    // })
    // axios.get('api/income').then(res => {
    //   this.$store.commit('statisticStore/setIncome', res.data[0].income)
    // })
  }
};
</script>
<style scoped>
.v-btn--outlined {
  border: thin solid #d8d8d8;
}

.theme--dark.v-btn--active:hover::before,
.theme--dark.v-btn--active::before {
  background: rgb(255, 168, 0);
}
</style>
