<template>
    <div style="width: 100%;height: 100%; " class="rounded-lg">
        <div ref="chart" id="chart" v-if="seriesClient.length != 0" class="pa-5 rounded-lg" >
            <h2>Clients</h2>
            <VueApexCharts type="donut" v-if="seriesClient.length != 0" :options="chartClient" :series="seriesClient">
            </VueApexCharts>
        </div>
        <div style="background-color: white;" class="chartDefault rounded-lg pa-5" v-if="seriesClient.length == 0">
            <h2>Clients</h2>
            <div class="red--text" style="font-size: 15px; opacity: 0.5;">No clients yet</div>
        </div>
        <div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from "axios";

export default {
    components: {
        VueApexCharts
    },
    data() {
        return {
            seriesClient: [],
            chartClient: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
        }
    },
    watch: {
        clientStatistics(val) {
            this.chartClient.labels = [];
            this.seriesClient = []
            for (const key in val) {
                if (val[key]['name']) {
                    this.chartClient.labels.push(val[key]['name'])
                    this.seriesClient.push(val[key]['client'])
                }
            }
        },
    },
    computed: {
        clientStatistics() {
            return this.$store.getters['statisticStore/clientStatistics']
        },
    },
    // mounted() {
    //     this.chartClient.labels = [];
    //     this.seriesClient = []
    //     let chartClient = [] ;
    //     let seriesClientArr = [] ;
    //     for (const key in this.clientStatistics) {
    //         if (this.clientStatistics[key]['name']) {
    //             chartClient.push(this.clientStatistics[key]['name'])
    //             seriesClientArr.push(this.clientStatistics[key]['client'])
    //         }
    //     }
    //     this.chartClient.labels = chartClient
    //     this.seriesClient = seriesClientArr
    // },

    created() {
        axios.get('api/clientStatistics').then(res => {
      this.$store.commit('statisticStore/setClientStatistics', res.data)
    })
    }
}

</script>

<style></style>