<template>
  <div style="width: 100%;height: 100%;">
    <div id="chart" v-if="seriesContanct.length != 0" class="pa-5">
      <h2>Contacts</h2>
      <VueApexCharts type="donut" v-if="seriesContanct.length != 0" :options="chartOptionsContact"
        :series="seriesContanct"></VueApexCharts>
    </div>
    <div style="background-color: white;" class="chartDefault pa-5" v-if="seriesContanct.length == 0">
      <h2>Contacts</h2>
      <div class="red--text" style="opacity: 0.5; font-size: 15px;">No contacts yet</div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from "axios";

export default {
  components: {
    VueApexCharts
  },
  data() {
    return {
      seriesContanct: [],
      chartOptionsContact: {
        chart: {
          type: 'pie',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  },
  watch: {
    contactStatistics(val) {
      this.chartOptionsContact.labels = [];
      this.seriesContanct = []
      for (const key in val) {
        if (val[key]['name']) {
          this.chartOptionsContact.labels.push(val[key]['name'])
          this.seriesContanct.push(val[key]['contact'])
        }
      }
    },
  },
  created() {
    axios.get('api/contactStatistics').then(res => {
      this.$store.commit('statisticStore/setContactStatistics', res.data)
    })
  },
  computed: {
    contactStatistics() {
      return this.$store.getters['statisticStore/contactStatistics']
    },
  },
  // mounted() {
  //   this.chartOptionsContact.labels = [];
  //   this.seriesContanct = []
  //   for (const key in this.contactStatistics) {
  //     if (this.contactStatistics[key]['name']) {
  //       this.chartOptionsContact.labels.push(this.contactStatistics[key]['name'])
  //       this.seriesContanct.push(this.contactStatistics[key]['contact'])
  //     }
  //   }
  // }
}
</script>

<style></style>