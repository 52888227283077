export const login = {
    namespaced: true,
    state:{
        isLogin:false
    },
    getters:{
        isLogin(state){
            return state.isLogin
        }
    },
    mutations:{
        setLogin(state, value){
            state.isLogin = value
        }
    },
    actions: {
    }
}