<template>
    <div style="width: 100%;height: 100%;">
        <div id="chart" v-if="!closureDateView" class=" ">
            <VueApexCharts type="bar" v-if="options.xaxis.categories.length != 0" :options="options" :series="series">
            </VueApexCharts>
        </div>
        <div style="background-color: white;" id="chart" v-if="closureDateView" class="chartDefault pa-8">
        </div>
        <div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from "axios";

export default {
    components: {
        VueApexCharts
    },
    data() {
        return {
            series: [{
                name: 'total syrian price',
                data: []
            },],
            closureDateView: false,
            options: {
                chart: {
                    id: 'total price'
                },
                xaxis: {
                    categories: []
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]

            },
        }
    },
    watch: {
        allClosure_date(val) {
            if (val.length == 0) {
                this.closureDateView = true
            } else {
                this.closureDateView = false
            }
            this.options.xaxis.categories = []
            this.series[0].data = []
            for (const key in val) {
                if (val[key]['closure_month']) {
                    this.options.xaxis.categories.push(val[key]['closure_month'])
                    this.series[0].data.push(val[key]['total_price'])
                }
            }
        },
    },
    computed: {
        allClosure_date() {
            return this.$store.getters['statisticStore/allClosure_date']
        },
    },
    // mounted() {
    //     if (this.allClosure_date.length == 0) {
    //         this.closureDateView = true
    //     } else {
    //         this.closureDateView = false
    //     }
    //     this.options.xaxis.categories = []
    //     this.series[0].data = []
    //     for (const key in this.allClosure_date) {
    //         if (this.allClosure_date[key]['closure_month']) {
    //             this.options.xaxis.categories.push(this.allClosure_date[key]['closure_month'])
    //             this.series[0].data.push(this.allClosure_date[key]['count'])
    //         }
    //     }
    // },

    created() {
        axios.get('api/closureDate').then(res => {
            this.$store.commit('statisticStore/setAllClosure_date', res.data)
        })
    },
}

</script>

<style></style>