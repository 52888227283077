import Vue from 'vue'
import Vuex from 'vuex'
import { statisticStore } from './statistic-store/index.js';
import { login } from './login-store/index.js'
Vue.use(Vuex)
const store = new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        statisticStore,
        login
    }
});
export default store;