<template>
    <div class="px-8">
        <div class="d-flex justify-center align-content-center">
            <statistics-table />
        </div>
    </div>
</template>

<script>
import StatisticsTable from '../components/StatisticsTable.vue';

export default {
components:{
    StatisticsTable
}
}
</script>

<style>

</style>