<template>
  <div class="px-8">
    <div class="my-2">
      <div class="d-flex justify-space-between flex-wrap rounded-lg px-5" style="align-items: center; background-color: white;">
        <span>
          <h3 >Total Client : {{ total }} </h3>
        </span>
        <span>
          <h3 >Income : {{ income == null ? 0 : income}} .SYP</h3>
        </span>
        <span class="clock d-flex justify-space-around flex-wrap">
          <h3 >{{ date }}</h3>
          <h3 >
            <pre> </pre>
          </h3>
          <h3 >{{ clockContent }}</h3>
        </span>
      </div>
    </div>
    <v-row class="my-2">
      <v-col  md="6" sm="12" lg="4" xl="3" xs="12">
        <contact  style="background-color: white;" class="rounded-lg "/>
      </v-col>
      <v-col  md="6" sm="12" lg="4" xl="3" xs="12">
        <potential-client  style="background-color: white;" class="rounded-lg "/>
      </v-col>
      <v-col  md="6" sm="12" lg="4" xl="3" xs="12">
        <clients  style="background-color: white;" class="rounded-lg "/>
      </v-col>
      <v-col  md="6" sm="12" lg="4" xl="3" xs="12">
        <count-client style="background-color: white;" class="rounded-lg "/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <statistics-table />
      </v-col>
    </v-row>
  </div>
</template>
  
<script>
// import ApexCharts from 'apexcharts'
import VueApexCharts from 'vue-apexcharts'
import axios from "axios";
import contact from '../components/contact.vue';
import clients from '../components/client.vue';
import PotentialClient from '../components/PotentialClients.vue';
import StatisticsTable from '../components/StatisticsTable.vue';
import CountClient from '../components/CountClient.vue'
export default {
  components: {
    // ApexCharts,
    VueApexCharts,
    contact,
    clients,
    PotentialClient,
    StatisticsTable,
    CountClient
  },
  data() {
    return {
      date: '',
      clockContent: '',
    }
  },
  computed: {
    total() {
      return this.$store.getters['statisticStore/total']
    },
    income() {
      return this.$store.getters['statisticStore/income']
    }
  },
  methods: {
    clock() {
      var d = new Date();
      var s = d.getSeconds();
      var m = d.getMinutes();
      var h = d.getHours();
      this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.clockContent =
        ("0" + h).substr(-2) + ":" + ("0" + m).substr(-2) + ":" + ("0" + s).substr(-2);
    },

    allDataApi() {
      axios.get('api/closureDate').then(res => {
        this.$store.commit('statisticStore/setAllClosure_date', res.data)
      })
      axios.get('api/totalClient').then(res => {
        this.$store.commit('statisticStore/setTotalClient', res.data)
      })
      axios.get('api/income').then(res => {
        this.$store.commit('statisticStore/setIncome', res.data[0].income)
      })
    }
  },
  created() {
    setInterval(this.clock, 1000);
    axios.get('api/totalClient').then(res => {
      this.$store.commit('statisticStore/setTotalClient', res.data)
    })
    axios.get('api/income').then(res => {
      this.$store.commit('statisticStore/setIncome', res.data[0].income)
    })
  },
};
</script>  
<style lang="scss">
.chart {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.chartDefault {
  border-radius: 5px;
}

.clock {
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0px;
  border-radius: 5px;
}

</style>
  