import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import information from '../views/information.vue';
import employees from '../views/employees.vue';
import ContactPage from '../views/ContactPage.vue';
import PotentialClients from '../views/PotentialPage.vue';
import Clients from '../views/client.vue';
import CountClient from '../views/CcPage.vue';
import StPage from '../views/StPage.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/EntryPage',
    name: 'EntryPage',
    component: information
  },
  {
    path: '/Employees',
    name: 'Employees',
    component: employees
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: ContactPage
  },
  {
    path: '/PotentialClients',
    name: 'PotentialClients',
    component: PotentialClients
  },
  {
    path: '/Clients',
    name: 'Clients',
    component: Clients
  },
  {
    path: '/countClient',
    name: 'countClient',
    component: CountClient
  }, 
  {
    path: '/statisticsTable',
    name: 'statisticsTable',
    component: StPage
  }, 
]

const router = new VueRouter({
  routes
})

export default router
