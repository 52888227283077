<template>
    <div class="px-8">
        <div style="background-color: white;" class="rounded-lg pa-6 d-flex justify-center align-content-center">
            <div :class="{'div-width-50': $vuetify.breakpoint.mdAndUp}">
                <contact />
            </div>
        </div>
    </div>
</template>

<script>
import contact from '../components/contact.vue';

export default {
components:{
    contact
}
}
</script>

<style>
.div-width-50 {
  width: 50%;
}
</style>