export const statisticStore = {
  namespaced: true,
  state: {
    data: '',
    id: null,
    view: '',
    allClosure_date: [],
    potentialClientStatistics: [],
    contactStatistics: [],
    clientStatistics: [],
    total: 0,
    employees: [],
    income: 0,
    folowingBy: [],
    review: false,
    images: [],
    employeesWithTrashed:[]
  },
  getters: {
    id(state) {
      return state.id
    },
    data(state) {
      return state.data
    },
    view(state) {
      return state.view
    },
    allClosure_date(state) {
      return state.allClosure_date
    },
    potentialClientStatistics(state) {
      return state.potentialClientStatistics
    },
    contactStatistics(state) {
      return state.contactStatistics
    },
    clientStatistics(state) {
      return state.clientStatistics
    },
    total(state) {
      return state.total
    },
    employees(state) {
      return state.employees
    },
    employeesWithTrashed(state) {
      return state.employeesWithTrashed
    },
    income(state) {
      return state.income
    },
    folowingBy(state) {
      return state.folowingBy
    },
    review(state) {
      return state.review
    },
    images(state) {
      return state.images
    },
    // viewWithImage(state) {
    //   return state.viewWithImage
    // }
  },
  mutations: {
    setId(state, id) {
      state.id = id
    },
    setData(state, data) {
      state.data = data
    },
    removeItem(state, id) {
      state.data = state.data.filter((d) => {
        return d.id != id
      })
    },
    updateData(state, data) {
      let newData = [...state.data]
      newData = newData.filter((d) => {
        return d.id != data.id
      })
      if (data.is_closure == true) {
        data.is_closure = 'Yes'
      }
      if (data.is_closure == false) {
        data.is_closure = 'No'
      }
      newData.push(data);
      state.data = newData
    },
    setView(state, data) {
      state.view = data
    },
    setAllClosure_date(state, data) {
      state.allClosure_date = data
    },
    setPotentialClientStatistics(state, data) {
      state.potentialClientStatistics = data
    },
    setContactStatistics(state, data) {
      state.contactStatistics = data
    },
    setClientStatistics(state, data) {
      state.clientStatistics = data
    },
    setTotalClient(state, data) {
      state.total = data[0].client
    },
    setEmployees(state, data) {
      state.employees = [...data, ...state.employees]
    },
    setAllEmployees(state, data) {
      state.employees = data
    },
    setAllEmployeesWithTrashed(state, data) {
      state.employeesWithTrashed = data
    },
    deleteEmployee(state, data) {
      let newData = [...state.employees]
      newData = newData.filter((d) => {
        return d.id != data.id
      })
      state.employees = newData
    },
    setIncome(state, income) {
      return state.income = income
    },
    setFolowingBy(state, data) {
      state.folowingBy = data
    },
    setReview(state, value) {
      state.review = value
    },
    setImages(state, pyaload) {
      state.images = pyaload
    },
  },
  actions: {
    handleDataView({ commit }, data) {
      let afterEditData = data;
      afterEditData.map(row => {
        if (row.is_closure == true) {
          row.is_closure = 'Yes'
        }
        if (row.is_closure == false) {
          row.is_closure = 'No'
        }
      })
      commit('setData', afterEditData)
    },
  }
}
