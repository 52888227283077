<template>
  <div style="width: 100%; height: 100%;">
    <div  id="chart" v-if="seriesPotentialClient.length != 0" class=" pa-5" >
      <h2>Potential Clients</h2>
      <VueApexCharts type="donut" v-if="seriesPotentialClient.length != 0" :options="chartPotentialClient"
        :series="seriesPotentialClient"></VueApexCharts>
    </div>
    <div style="background-color: white;" class="chartDefault pa-5" v-if="seriesPotentialClient.length == 0">
      <h2>Potential Clients</h2>
      <div v-if="seriesPotentialClient.length == 0" class="red--text" style="font-size: 15px; opacity: 0.5;">No potential clients yet
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from "axios";

export default {
  components: {
    VueApexCharts
  },
  data() {
    return {
      seriesPotentialClient: [],
      chartPotentialClient: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  },
  watch: {
    potentialClientStatistics(val) {
      this.chartPotentialClient.labels = [];
      this.seriesPotentialClient = []
      for (const key in val) {
        if (val[key]['name']) {
          this.chartPotentialClient.labels.push(val[key]['name'])
          this.seriesPotentialClient.push(val[key]['potential_client'])
        }
      }
    },
  },
  computed: {
    potentialClientStatistics() {
      return this.$store.getters['statisticStore/potentialClientStatistics']
    },
  },
  created() {
    axios.get('api/potentialClientStatistics').then(res => {
      this.$store.commit('statisticStore/setPotentialClientStatistics', res.data)
    })
  },
  // mounted() {
  //   this.chartPotentialClient.labels = [];
  //   this.seriesPotentialClient = []
  //   for (const key in this.potentialClientStatistics) {
  //     if (this.potentialClientStatistics[key]['name']) {
  //       this.chartPotentialClient.labels.push(this.potentialClientStatistics[key]['name'])
  //       this.seriesPotentialClient.push(this.potentialClientStatistics[key]['potential_client'])
  //     }
  //   }
  // }
}
</script>

<style></style>