<template>
    <div class="px-8">
        <div style="background-color: white;" class="rounded-lg pa-6 d-flex justify-center align-content-center">
            <div :class="{'div-width-50': $vuetify.breakpoint.mdAndUp}">
                <count-client />
            </div>
        </div>

    </div>
</template>

<script>
import CountClient from '../components/CountClient.vue';

export default {
    components: {
        CountClient
    }
}
</script>

<style></style>